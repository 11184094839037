import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import {OnAirProvider} from "./contexts/onAirContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <OnAirProvider>
            <App />
          </OnAirProvider>
      </BrowserRouter>
  </React.StrictMode>
);
