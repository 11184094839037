import React, {useState} from "react";

const OnAirContext = React.createContext([
    {},
    p => {
    }
])

const defaultOnAir = {
    isLoaded: false,
    now: {
        ts: '',
        title: '',
        author: '',
        cover: ''
    },
    before: []
}

const OnAirProvider = (props) => {
    const [state, setState] = useState(defaultOnAir)
    return (<OnAirContext.Provider value={[state, setState]}>
        {props.children}
    </OnAirContext.Provider>)
}

export {OnAirContext, OnAirProvider}