import React, {useRef} from "react"
import {
    Link,
    Outlet,
    useRoutes,
} from "react-router-dom";


import './../styles/app.css'
import Contact from "./Contact";
import Lista from "./Lista";
import OnAir from "./OnAir";
import Controls from "./Controls";

const MCRadioStream = "https://stream4.nadaje.com:8625/mcradio"
const MCLogoThumb = 'https://www.mcradio.pl/public/img/logo@2x.png'

function App() {
    let routes = [
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: "/kontakt",
                    element: <Contact/>,
                },
                {
                    path: "/lista",
                    element: <Lista/>,
                },
            ],
        },
        {
            path: "*",
            element: <NoMatch/>
        }
    ];
    let element = useRoutes(routes);

    return (
        <section className={"main"}>
            {element}
        </section>
    )
}

function Layout() {
    const audioRef = useRef();

    return (
        <>
            <Controls audioRef={audioRef}
                      mcLogoSrc={MCLogoThumb}
                      audioSrc={MCRadioStream}/>
            <section id="content">
                <OnAir/>
                <Outlet/>
            </section>
            <nav>
                <ul>
                    <li>
                        <Link to="/lista">Co&nbsp;Gramy</Link>
                    </li>
                    <li>
                        <Link to="/kontakt">Kontakt</Link>
                    </li>
                </ul>
            </nav>
            <audio src={MCRadioStream} ref={audioRef} preload={"none"}/>
        </>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Tutaj nic nie ma...</h2>
            <p>
                <Link to="/">Powrót do początku...</Link>
            </p>
        </div>
    );
}

export default App