import React from 'react'
import {Dna} from "react-loader-spinner"
import OnAirPlayedItem from "./OnAirPlayedItem";
import useOnAir from "../hooks/useOnAir";

const OnAirPlayed = () => {
    const {now, before, isLoaded} = useOnAir()

    if (!isLoaded) {
        return <Dna
            visible={true}
            height="60"
            width="60"
            ariaLabel="dna-loading"
            wrapperStyle={{margin: '0 auto', display: 'block', textAlign: 'center'}}
            wrapperClass="dna-wrapper"
        />
    }

    let itemsToDisplay = []
    if (before !== undefined && before.length > 0) {
        before.forEach(b => b.ts !== now.ts ?
            itemsToDisplay.push(b) : null
        )
        itemsToDisplay = itemsToDisplay
            .filter(v => v)
            .slice(0, 5)
    }

    return (
        <>
            {itemsToDisplay.length ?
                itemsToDisplay.map(
                    played => {
                        return (<OnAirPlayedItem item={played} key={played.ts}/>)
                    }) : null
            }
        </>
    )
}

export default OnAirPlayed