import React, {useState, useEffect} from 'react';

// icons
import {
    IoPlaySharp,
    IoStopSharp,
} from 'react-icons/io5';

const Controls = ({audioRef, mcLogoSrc, audioSrc}) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const pause = () => {
        audioRef.current.pause()
        audioRef.src = ''
    }
    const play = () => {
        if (!audioRef.src) {
            audioRef.src = audioSrc
            audioRef.current.load()
        }
        audioRef.current.play()
    }

    useEffect(() => {
        isPlaying ? play() : pause()
    }, [isPlaying, audioRef]);

    return (
        <section className="controls-wrapper">
            <img src={mcLogoSrc} alt={"MC Radio Logo"}/>
            <div className="controls">
                <button onClick={togglePlayPause}>
                    {isPlaying ? <IoStopSharp className={"isPaused"}/> : <IoPlaySharp className={"isPlaying"}/>}
                </button>
            </div>
        </section>
    );
};

export default Controls;