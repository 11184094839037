import React from 'react';
import {Link} from "react-router-dom";

 function Contact() {
    return (
        <div id={"outlet"}>
            <div className={"headerWithClose"}>
                <Link to="/" className={"btn"}>X</Link>
                <h2 className={"text"}>Kontakt z nami</h2>
            </div>
            <div className={"tc"}>
                <p className={"emails"}>
                    <span>redakcja@mcradio.pl</span>
                    <span>reklama@mcradio.pl</span>
                    <span>promocja@mcradio.pl</span>
                </p>
                <p className={"txt"}>
                    SMS 71601 <br/>
                    <span style={{fontSize: '12px' }}>
                    z hasłem 'mcradio' <br/>
                    na początku  <br/>
                    ( koszt 1,23 zł z VAT )
                    </span>
                </p>
            </div>
        </div>
    )
}

export default Contact