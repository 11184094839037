export const apiHost = 'https://player.mcradio.pl'
// export const apiHost = 'http://localhost:8000'
export const coverHost = 'https://cover.mcradio.pl'
export const emptyThumbPath = 'https://cover.mcradio.pl/'
export const MCLogoThumb = 'https://www.mcradio.pl/public/img/logo@2x.png'
// export const onAirNowUrl = apiHost + '/api/v1/now'
export const onAirPlayedUrl = apiHost + '/api/v1/playedAt'
export const onAirNowAndBeforeUrl =  apiHost + '/api/v1/nowAndBefore'

export const thumbSrcPath = (coverSrc) => {
    let thumbPath = coverHost + coverSrc.replace('/public/covers', '');
    if (isCoverSrcEmpty(thumbPath)) {
        thumbPath = MCLogoThumb
    }
    return thumbPath
}

export const isCoverSrcEmpty = (coverSrc) => {
    if (coverSrc === emptyThumbPath) {
        return true;
    }
    return coverSrc === coverHost;
}
