import React from "react"
import OnAirPlayed from "./OnAirPlayed";
import {Link} from "react-router-dom";

function Lista() {
    return (
        <section id={"outlet"}>
            <div className={"headerWithClose"}>
                <Link to="/" className={"btn"}>X</Link>
                <h2 className={"text"}>Ostatnio graliśmy</h2>
            </div>
            <OnAirPlayed isVisible={true}></OnAirPlayed>
        </section>
    )
}

export default Lista