import React from 'react'
import {formatTsToDate} from "../fns/formats";

const OnAirPlayedItem = ({item}) => {
    const altContent = (item) => {
        return item === undefined ? '' :
            'Okładka do płyty: ' + item.author + ' / ' + item.title
    }

    return item === undefined ? <></> : (
        <div className={"playedItem"} key={item.ts}>
            <b className={"when"}>{formatTsToDate(item.ts)}</b>
            <div className={"what"}>
                <span className={"song"}>{item.title}</span>
                <span className={"author"}>{item.author}</span>
            </div>
            <figure className={"playedItemPreview"}>
                <img src={item.cover} alt={altContent(item)}/>
            </figure>
        </div>
    )
}

export default OnAirPlayedItem